import Keycloak, {
  type KeycloakInitOptions,
  type KeycloakLoginOptions,
} from 'keycloak-js';
import { Scope, AccessLevel, accessLevelValue } from 'usmart-common/dist/roles';
import { setTokens } from './slice';
import store from '../store';
import { RoleAccessLevel } from 'admin/roles/CreateRoleDialog';

const KEYCLOAK_DEFAULT_ROLES = [
  'default-roles-usmart',
  'offline_access',
  'uma_authorization',
];

const kcLoginOptions: KeycloakLoginOptions = {
  idpHint: 'login.gov',
};
if (import.meta.env.VITE_ALLOW_LOCAL_ACCOUNTS == 'true') {
  kcLoginOptions.idpHint = undefined;
}

const kcInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri:
    window.location.origin + import.meta.env.VITE_CHECK_SSO_PATH,
  pkceMethod: 'S256',
  enableLogging: import.meta.env.DEV,
};

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const _kc = new Keycloak({
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID,
  url: import.meta.env.VITE_KEYCLOAK_URL, // use env variable if available, else localhost
});

/**
 * The type returned with KeycloakIdTokens isn't quite correct, at least not with my build of KC Server.
 * This adds all the properties returned for a _valid_ ID token.
 */
type KeycloakIDTokenParsed = {
  acr: string | undefined;
  at_hash: string | undefined;
  aud: string | undefined;
  auth_time: number | undefined;
  azp: string | undefined;
  email: string | undefined;
  email_verified: boolean | undefined;
  exp: number | undefined;
  family_name: string | undefined;
  given_name: string | undefined;
  iat: number | undefined;
  iss: string | undefined;
  jti: string | undefined;
  name: string | undefined;
  nonce: string | undefined;
  preferred_username: string | undefined;
  session_state: string | undefined;
  sub: string | undefined;
  typ: string | undefined;
};

type AuthCallback = () => void;

const initKeycloak = (onAuthenticatedCallback: AuthCallback) => {
  _kc.init(kcInitOptions).then(() => {
    store.dispatch(setTokens({ idToken: _kc.token }));
  });
  onAuthenticatedCallback();
};

_kc.onTokenExpired = () => {
  _kc
    .updateToken(30)
    .then(() => store.dispatch(setTokens({ idToken: _kc.token })))
    .catch(() => {
      console.log('token update failed');
    });
};

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${_kc.token}`,
  };
};

const doLogin = () => _kc.login(kcLoginOptions);
const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getIdTokenParsed = () => _kc.idTokenParsed as KeycloakIDTokenParsed;

const isLoggedIn = () => _kc.authenticated;

const getUsername = () =>
  (_kc.idTokenParsed as KeycloakIDTokenParsed)?.preferred_username;

const getName = () => (_kc.idTokenParsed as KeycloakIDTokenParsed)?.name;

const getParsedToken = () => _kc.tokenParsed;

const gotoAccountManager = () => _kc.accountManagement();

const getAcctUrl = () => _kc.createAccountUrl();

const hasRoles = (roles: Array<string> | undefined = getRoles()) => {
  if (!roles) return false;
  roles.some(role =>
    _kc.hasResourceRole(role, import.meta.env.VITE_CLIENT_NAME)
  );
};

const hasRole = (role: string) =>
  _kc.hasResourceRole(role, import.meta.env.VITE_CLIENT_NAME);

const hasAScope = (scope: string[]): boolean => {
  const roles = getRoles();
  if (roles == null) return false;
  return (
    roles?.findIndex(r => {
      for (const part of r.split('.')) if (scope.includes(part)) return true;
      return false;
    }) !== -1
  );
};

/**
 * Determine if a user has *at least* enough permissions to perform an action.
 * @param scope Scope a user must have access to
 * @param accessLevel Minimum access level required for the action
 * @param district Optional, the district of the accessible item
 */
const hasAccess = (
  scope: Scope,
  accessLevel: AccessLevel,
  district?: string
): boolean => {
  const roles = getRoles();
  // user doesn't have any roles so will not have any access.
  if (!roles) {
    return false;
  }
  // user is an admin, will always have access to non-district items.
  if (hasAScope(['admin']) && !district) {
    return true;
  }
  for (const role of roles) {
    const parts = role.split('.');
    if (parts.length < 2) {
      continue;
    }
    const roleDistrict = parts[parts.length - 3].toUpperCase();
    const roleScope = parts[parts.length - 2];
    const roleAccessLevel = parts[parts.length - 1];
    let districtCheck = false;

    // user has global write/manage permissions, has access
    if (roleDistrict === 'GLOBAL') {
      if (
        roleAccessLevel === AccessLevel.write ||
        roleAccessLevel === AccessLevel.manage
      ) {
        return true;
      }
    }
    // matches district
    if (!district || roleDistrict === district) {
      // user is district admin, has access
      if (district && roleScope === Scope.admin) {
        return true;
      }
      districtCheck = true;
    }

    if (districtCheck && roleScope === scope) {
      //has scope
      if (!roleAccessLevel) {
        return false;
      } else if (
        roleAccessLevel === accessLevel ||
        accessLevelIsGreater(roleAccessLevel as AccessLevel, accessLevel)
      ) {
        return true;
      }
    }
  }
  return false;
};

const accessLevelIsGreater = (
  a: AccessLevel | undefined,
  b: AccessLevel | undefined
): boolean => accessLevelValue(a) > accessLevelValue(b);

const getRoles = () => {
  return _kc.resourceAccess?.[import.meta.env.VITE_CLIENT_NAME]?.roles.filter(
    r => !KEYCLOAK_DEFAULT_ROLES.includes(r)
  );
};

const updatePassword = () => {
  return _kc.login({ action: 'UPDATE_PASSWORD' });
};

const updateProfile = () => {
  return _kc.login({ action: 'UPDATE_PROFILE' });
};

const registerWebAuthnDevice = () => _kc.login({ action: 'webauthn-register' });

const registerTotpDevice = () => _kc.login({ action: 'CONFIGURE_TOTP' });

const getAuthServerUrl = () => _kc.authServerUrl;

const getCredentials = async () => {
  return await fetch(
    `${getAuthServerUrl()}/realms/usmart/account/credentials`,
    {
      headers: getHeaders(),
    }
  ).then(resp => resp.json());
};

const deleteCredential = async (id: string) => {
  return await fetch(
    `${getAuthServerUrl()}/realms/usmart/account/credentials/${id}`,
    {
      method: 'DELETE',
      headers: getHeaders(),
    }
  );
};

/**
 * Determine if a user is new. This is determined by the presence of an attribute that is cleared
 * after roles for the new user are processed.
 * @returns Whether the user is new or not.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNewUser = () => !!(_kc.idTokenParsed as any)?.new;

_kc.onAuthLogout = () => {
  store.dispatch(setTokens({ idToken: undefined }));
};

const keycloakProvider = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  getUsername,
  hasRole,
  hasRoles,
  getParsedToken,
  getName,
  gotoAccountManager,
  getAcctUrl,
  getIdTokenParsed,
  getRoles,
  updatePassword,
  updateProfile,
  registerWebAuthnDevice,
  registerTotpDevice,
  getAuthServerUrl,
  getCredentials,
  deleteCredential,
  hasAScope,
  hasAccess,
  isNewUser,
};

export default keycloakProvider;
